import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import pzLogo from "../assets/images/logo.svg";
import menuIcon from "../assets/icons/menu.svg";
import closeIcon from "../assets/icons/close.svg";
import "../styles/nav.css";

const Navigation = () => {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/");
  };

  const navigateToLogin = () => {
    window.location.href = "https://dashboard.zeepway.com/auth/login";
  };

  const navigateToRegistration = () => {
    window.location.href = "https://dashboard.zeepway.com/auth/register";
  };

  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <>
      <nav className="nav-bar">
        <div className="nav-header">
          <div className="nav-header-items">
            <img
              src={pzLogo}
              alt="payzeep logo"
              onClick={navigateToHome}
              style={{ cursor: "pointer" }}
              className="pzLogo"
            />
             {isNavVisible ? (
              <img
                src={closeIcon}
                alt="close"
                onClick={toggleNav}
                className="hamburger"
              />
            ) : (
              <img
                src={menuIcon}
                alt="menu"
                onClick={toggleNav}
                className="hamburger"
              />
            )}
          </div>
        </div>

        <div className={`nav-links ${isNavVisible ? "visible" : ""}`}>
          <NavLink
            exact
            to="/pricing"
            className="nav-link"
            activeClassName="active-link"
          >
            Pricing
          </NavLink>
          <NavLink
            to={"/for-developers"}
            className="nav-link"
            activeClassName="active-link"
          >
            For Developers
          </NavLink>
          <NavLink
            to={"/frequently-asked-questions"}
            className="nav-link"
            activeClassName="active-link"
          >
            FAQs
          </NavLink>
          <NavLink
            to={"/contact-us"}
            className="nav-link"
            activeClassName="active-link"
          >
            Contact Us
          </NavLink>
          <div className="nav-action">
            <p className="nav-link login-link" onClick={navigateToLogin}>
              Login
            </p>
            <button onClick={navigateToRegistration}>
              Create Free Account
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
