import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import headerImgLeft from "../assets/images/pricing-bg-left.svg";
import headerImgRight from "../assets/images/pricing-bg-right.svg";
import sendMoney from "../assets/images/send-money.svg";
import atmCard from "../assets/images/atm-card.svg";
import check from "../assets/icons/check-icon.svg";
import arrowRedirect from "../assets/icons/arrow-redirect-blue.svg";
import { ReactComponent as ChevronDownIcon } from "../assets/icons/chevron-down.svg";
import "../styles/pricing.css";

const FAQItem = ({ faq, index, toggleFAQ, activeIndex }) => (
  <div className={`faq-item ${activeIndex === index ? "open" : ""}`}>
    <div className="faq-question" onClick={() => toggleFAQ(index)}>
      {faq.question}
      <ChevronDownIcon
        className={`chevron-icon ${activeIndex === index ? "rotated" : ""}`}
      />
    </div>
    <div className="faq-answer">{faq.answer}</div>
  </div>
);

const Pricing = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What types of payments can I accept with PayZeep Payment Gateway?",
      answer: "PayZeep accepts all major credit cards including Visa, Mastercard and Verve.",
    },
    {
      question: "How do I integrate PayZeep Payment Gateway into my website or system?",
      answer: "Our payment gateway allows you to securely accept online payments on your website or mobile app. Our gateway uses industry-standard encryption and tokenization to protect sensitive customer data. Integrating our payment gateway into your website or app is easy - we provide API documentation and integrations for all major e-commerce platforms. Our documentation includes code samples and step-by-step instructions to get you up and running quickly.",
    },
    {
      question: "Can I access transaction reports and analytics?",
      answer: "Yes, PayZeep provides a transaction dashboard and reporting tools so you can closely monitor your payments activity. You can filter reports by date range, payment method, location and other parameters. Reports can be exported to CSV or PDF.",
    },
    {
      question: "Is there a setup fee for using PayZeep’s Payment Gateway?",
      answer: "There are no setup fees for signing up with PayZeep. Our pricing is based on a competitive transaction fee percentage. We do not charge monthly fees or require long-term contracts.",
    },
  ];

  return (
    <>
      <Navigation />
      <div className="page-header">
        <div>
          <img src={headerImgLeft} alt="" />
        </div>
        <h1>
          Pricing Plans for <br /> ZeepWay
        </h1>
        <div className="header-right">
          <img src={headerImgRight} alt="" />
        </div>
      </div>
      <div className="pricing-header-text">
        <p>
          We believe in transparent and flexible pricing to suit businesses of
          all sizes. Our Payment Gateway offers various plans designed to meet
          your specific needs, whether you're a startup, a growing enterprise,
          or an established business.
        </p>
      </div>
      <div className="pricing-categories">
        <div className="pricing-category">
          <img src={sendMoney} alt="transfers" />
          <p>For Transfers / Payout Transactions</p>
          <h2>₦30</h2>
          <div className="border"></div>
          <div className="list">
            <div className="list-item">
              <img src={check} alt="check" />
              <span>Flat rate.</span>
            </div>
            <div className="list-item">
              <img src={check} alt="check" />
              <span>VAT Exclusive.</span>
            </div>
          </div>
        </div>
        <div className="pricing-category">
          <img src={atmCard} alt="card" />
          <p>For Card Transactions</p>
          <h2>1.5%</h2>
          <div className="border"></div>
          <div className="list">
            <div className="list-item">
              <img src={check} alt="check" />
              <span>Capped at ₦2000.</span>
            </div>
            <div className="list-item">
              <img src={check} alt="check" />
              <span>VAT Exclusive.</span>
            </div>
            <div className="list-item">
              <img src={check} alt="check" />
              <span>
              The maximum amount of fees you will ever pay for each transaction is ₦2000.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="pricing-faq">
        <h2>Some Common Questions</h2>
        <div className="faq-redirect">
          <span>Check all frequently asked questions,</span>
          <Link to={"/frequently-asked-questions"}>
            See all <img src={arrowRedirect} alt="redirect" />
          </Link>
        </div>
        <div className="faq-section">
          {faqData.map((faq, index) => (
            <FAQItem
              key={index}
              faq={faq}
              index={index}
              toggleFAQ={toggleFAQ}
              activeIndex={activeIndex}
            />
          ))}
        </div>
        <div className="faq-redirect">
          <span>Still have more questions?</span>
          <Link to={"/contact-us"}>
            Contact Us <img src={arrowRedirect} alt="redirect" />
          </Link>
        </div>
      </div>
      <GetStarted />
      <Footer />
    </>
  );
};
export default Pricing;
