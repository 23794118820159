import React from "react";
import arrowRedirect from "../assets/icons/arrow-redirect.svg";
import "../styles/newsletter.css";

const NewsLetter = () => {
  return(
    <>
    <div className="newsletter-container">
      <h3>Subscribe To Our Monthly Newsletter And Updates</h3>
      <p>Get our latest updates on all our platforms and news.</p>
      <div className="sub-input">
        <input 
        type="text" 
        placeholder="Enter your email address"
        style={{border:'none'}}/>
        <button className="subscribe-btn">
          Subscribe
          <img src={arrowRedirect} alt="redirect" />
          </button>
      </div>

    </div>
    </>
  )
}
export default NewsLetter;