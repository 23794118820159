import React from "react";
import { Link } from "react-router-dom";
import pzLogo from "../assets/images/logo.svg";
import facebookIcon from "../assets/icons/facebook.svg";
import twitterIcon from "../assets/icons/twitter.svg";
import instagramIcon from "../assets/icons/instagram.svg";
import "../styles/footer.css";

const Footer = () => {
  const navigateToLogin = () => {
    window.location.href = "https://dashboard.zeepway.com/auth/login";
  };

  const navigateToRegistration = () => {
    window.location.href = "https://dashboard.zeepway.com/auth/register";
  };

  const navigateToFacebook = () => {
    window.location.href = "https://www.facebook.com/payzeep";
  };

  const navigateToTwitter = () => {
    window.location.href = "https://twitter.com/payzeepng";
  };

  const navigateToInstagram = () => {
    window.location.href = "https://www.instagram.com/payzeep/";
  };

  return (
    <>
      <div className="footer-section">
        <div className="footer-container">
          <div>
            <img src={pzLogo} alt="logo" />
          </div>
          <div className="footer-body">
            <div className="footer-column">
              <span>Menu</span>
              <div className="footer-links">
                <div className="mobile-col mobile-col1">
                  <Link to="/pricing" className="footer-link">
                    Pricing
                  </Link> <br/>
                  <Link to="/for-developers" className="footer-link">
                    For Developers
                  </Link>
                </div>
                <div className="mobile-col">
                  <Link
                    to="/frequently-asked-questions"
                    className="footer-link"
                  >
                    FAQs
                  </Link>
                  <p onClick={navigateToLogin}>Login</p>
                </div>
                <p onClick={navigateToRegistration}>Create Free Account</p>
              </div>
            </div>
            <div className="footer-column">
              <span>Contact us</span>
              <div className="footer-links">
                <div className="contact-links">
                <p>Lagos: 13 Town Planning Way, Ilupeju, Lagos State</p>
                <p>Abuja: 36, Nouckachott Avenue, Wuse, Zone 1, Abuja</p>
                <p>(+234)9088220236</p>
                <a href="mailto:sales@payzeep.com?subject=Interested%20In%20Working%20With%20You">
                  Support@payzeep.com
                </a>
                </div>
                <div className="footer-icons">
                  <img
                    src={facebookIcon}
                    alt="facebook"
                    onClick={navigateToFacebook}
                  />
                  <img
                    src={twitterIcon}
                    alt="twitter"
                    onClick={navigateToTwitter}
                  />
                  <img
                    src={instagramIcon}
                    alt="instagram"
                    onClick={navigateToInstagram}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-links">
          <div className="copyright">
          <span>© 2023 PayZeep</span>
          <span>Powered by Paymi Solutions</span>
          </div>
          <div>
            <span>Terms & conditions</span>
            <span>Privacy policy</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
