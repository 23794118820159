import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import ContactUs from "../pages/ContactUs";
import Pricing from "../pages/Pricing";
import FAQs from "../pages/FAQs";
import Developers from "../pages/Developers";

const AppRoutes = () => {
  return(
    <>
    <Routes>
      <Route path ="/" Component={Home} />
      <Route path="/contact-us" Component={ContactUs} />
      <Route path="/pricing" Component={Pricing} />
      <Route path="/frequently-asked-questions" Component={FAQs} />
      <Route path="/for-developers" Component={Developers} />
    </Routes>
    </>
  )
}

export default AppRoutes;