import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import Navigation from "../components/Navigation";
import headerImgLeft from "../assets/images/pricing-bg-left.svg";
import headerImgRight from "../assets/images/pricing-bg-right.svg";
import "../styles/dev.css";

const Developers = () => {
  return (
    <>
      <div className="doc-container">
        <Navigation />
        <div className="page-header">
          <div>
            <img src={headerImgLeft} alt="" />
          </div>
          <h1>Zeepway Documentation</h1>
          <div className="header-right">
            <img src={headerImgRight} alt="" />
          </div>
        </div>
        <div className="doc-header-text">
          <span>
            Welcome to the Zeepway Documentation. Here, you will learn how to
            use and integrate our products and services.
          </span>
        </div>
        <div className="doc-body">
          <div className="doc-intro">
            <h2>Zeepway Payment Plugin</h2>
            <h4>
              Integration, requirements and how to use the payzeep payment
              plugin.
            </h4>
            <ol>
              <li>
               We are providing you with a script and CSS to add to your HTML
                page.
              </li>
              <li>
                After successfully adding the CSS and script, you can
                instantiate our zeepway instance to work with the plugin.
              </li>
              <li>To create a zeepject instance you will provide:</li>
              <ul>
                <li>api_key (public)</li>
                <li>onClose (optional)</li>
                <li>amount</li>
                <li>email</li>
                <li>name</li>
                <li>clientRef</li>
                <li>chargeUser</li>
              </ul>
              <li>
                After instantiating the zeepway plugin, call zeepway.start()
              </li>
              <li>
                You will see a zeepway modal where you can make payment with
                your card, bank, ussd etc.
              </li>
              <li>
                For card transaction with a card that requires pin, you will be
                prompted to enter your pin and an otp will be sent to you from
                your card provider.
              </li>
              <li>
                For card transaction with a card that sends redirect link, you
                will be redirected to the payment gateway to complete your
                payment.
              </li>
              <li>
                When the transaction is completed, for a successful transaction
                with pin charge you will be redirected to the live callback url
                that was provided in your dashboard's settings page.
              </li>
              <li>
                The onClose is an optional parameter that you can provide when
                instantiating a zeepway instance. It should be a function and is
                called when a user closes the plugin and the onClose function is
                present.
              </li>
              <li>
                The clientRef is an optional parameter for your own transaction
                reference, this reference is returned in the webhook and call
                back pages. clientRef must be unique per transaction.
              </li>
              <li>
                If you wish to pass the transaction charges to the user set the
                chargeUser to 'true.'
              </li>
            </ol>
          </div>
          <div className="code-snippet-section">
            <div className="code-snippet-box">
              <pre>
                <code>
                  &lt;!DOCTYPE html&gt; <br />
                  &lt;html lang="en"&gt; <br />
                  &lt;head&gt;
                  <br />
                  &lt;meta charset="utf-8"&gt; <br />
                  &lt;meta name="viewport" content="width=device-width,
                  initial-scale=1"&gt; <br />
                  &lt;title&gt;Zeepway Plugin &lt;/title&gt; <br />
                  &lt;link rel="stylesheet"
                  href="https://staging.zeepjet.com/zeepjet-main.css"&gt; <br />
                  &lt;/head&gt;
                  <br />
                  <br />
                  &lt;body&gt; <br />
                  <br />
                  &lt;!-- Sample form --&gt; <br />
                  &lt;form id="payForm"&gt; <br />
                  &lt;button type="submit" class="btn btn-primary"
                  id="payButton"&gt;Pay&lt;/button&gt; <br />
                  &lt;/form&gt;
                  <br />
                  <br />
                  &lt;!-- Zeepway Javascript plugin --&gt; <br />
                  &lt;script src="https://staging.zeepjet.com/zeepjet-main.js"
                  defer&gt; <br />
                  &lt;/script&gt;
                  <br />
                  <br />
                  &lt;!-- import cleave --&gt; <br />
                  &lt;script
                  src="https://cdnjs.cloudflare.com/ajax/libs/cleave.js/1.6.0/cleave.min.js"&gt;&lt;/script&gt;{" "}
                  <br />
                  <br />
                  &lt;!-- your project javascript file --&gt; <br />
                  &lt;script src="js/project.js" defer&gt;&lt;/script&gt;
                  <br />
                  <br />
                  &lt;/body&gt;
                  <br />
                  <br />
                  &lt;/html&gt; <br />
                </code>
              </pre>
            </div>
            <p>index.html</p>
          </div>
          <div className="code-snippet-section">
            <div className="code-snippet-box">
              <pre>
                <code>
                  {`var elements;

                    const myForm = document.getElementById("payForm");
                    
                    const submitButton = document.getElementById("payButton");

                    submitButton.addEventListener("click", function (e) {

                    e.preventDefault();

                    elements = new zeepway({
                      api_key: TEST_PUK_7204b86796d8e20......, // your api key
                      onClose, // optional function called when the close button is clicked
                      amount: document.getElementById("amount").value,
                      email: document.getElementById("email").value,
                      name: document.getElementById("name").value,
                      clientRef: "xondalskdnoas", // generate a unique client ref
                      chargeUser: false // set trie if you want pass transaction charges to the user
                    });
                  
                    elements.start();
                  });`}
                </code>
              </pre>
            </div>
            <p>project.js</p>
          </div>
          <div>
            <h4>Webhook</h4>
            <p>
              A webhook is sent after every transaction to the URL provided in
              the Dashboard.
            </p>
          </div>
          <div className="code-snippet-section">
            <div className="code-snippet-box">
              <pre>
                <code>
                  {`
                  curl -X 'POST' \

                      'https://YOUR_WEBHOOKURL.com' \

                      -H 'accept: application/json' \

                      -H 'Content-Type: application/json' \

                      -H 'authorization: "LIVE_PRK_77XXXXXXXXXXXXXXXXXXXXXXX84"' \ // YOUR PRIMARY KEY

                      -d '{
                        "customer_name": "Godwin",
                        "customer_email": "godwin@zeepway.com",
                        "amount": "100",
                        "status": "SUCCESSFUL",
                        "reference": "1702500236_ac933df3b168a9e9cfaa63bf53b7b7",
                        "client_ref": "9e9cfaa63bf53b7b7",
                        "channel": "card",
                        "description": "Approved by Financial Institution",
                        "card_expiry_month": "06",
                        "card_expiry_year": "25",
                        "card_number": "524282XXXXXXXX1952",
                        "card_type": "MASTERCARD",
                        "card_issuer": "MASTERCARD FIRST CITY MONUMENT BANK PLC CREDIT"
                    }',
                      `}
                </code>
              </pre>
            </div>
            <p>Sample webhook</p>
            <h4>Webhook Details</h4>
            <ul>
              <li>Endpoint must use the POST Method.</li>
              <li>
                A 200 response must be returned to prevent the webhook from
                being sent again.
              </li>
              <li>
                status indicates the final state of the transaction, and must be
                FAILED or SUCCESSFUL.
              </li>
              <li>
                Your Private key will be sent along with the headers, please ensure
                it matches your records.
              </li>
              <li>client_ref is your reference provided on transaction.</li>
            </ul>
          </div>
          <div>
            <h4>Call Backs</h4>
            <p>
              For transactions processed through a link, we redirect to your
              CallBack url after transaction has been completed.
            </p>
          </div>
          <div className="code-snippet-section">
            <div className="code-snippet-box">
              <pre>
                <code>
                  {`
                      YOUR_CALL_BACK_URL?reference=1702500236_ac933df3b168a9e9cfaa63bf53b7b7&client_ref=9e9cfaa63bf53b7b7
                      `}
                </code>
              </pre>
            </div>
            <p>Sample CallBack</p>
            <h4>
              The Following Parameters are passed to the CallBack URL
              Parameters:
            </h4>
            <ul>
              <li>reference: This is ZeepWay's transaction reference.</li>
              <li>
                client_ref: This is your client reference provided during
                transaction.
              </li>
            </ul>
          </div>
          <div>
            <h4>Transaction Query</h4>
            <p>
              You can use the transaction query endpoint to fetch details of the
              transaction, providing zeepway's unique reference.
            </p>
          </div>
          <div className="code-snippet-section">
            <div className="code-snippet-box">
              <pre>
                <code>
                  {`
                      GET: https://staging-api.zeepjet.com/v1/transactions/query?reference=1703161703_164d68e46bc5c341e47dabfb74d0e5

                      {
                        "message": "Transaction Retrieved",
                        "callbackUrl": "test-callbak-url",
                        "transaction": {
                          "id": 405,
                          "customer_name": "Godwin",
                          "customer_email": "godwin@zeepway.com",
                          "amount": 50,
                          "status": "FAILED",
                          "reference": "1703161703_164d68e46bc5c341e47dabfb74d0e5",
                          "channel": "card",
                          "fee": 0,
                          "amount_settled": 0,
                          "description": "Insufficient Funds: Your card cannot be charged due to insufficient funds. Please try another card or fund your card and try again.",
                          "response_code": null,
                          "authorization": "pin",
                          "card_expiry_month": "06",
                          "card_expiry_year": "25",
                          "card_number": "524XXXXXXXXXXXX52",
                          "card_type": "NA",
                          "card_issuer": null,
                          "bank": null,
                          "country": null,
                          "webhook": false,
                          "charge_user": false,
                          "user_id": 1,
                          "created_at": "2023-12-21T12:28:23.065Z",
                          "updated_at": "2023-12-21T12:37:47.297Z"
                        }
                      }
                      `}
                </code>
              </pre>
            </div>
            <p>Transactions Query</p>
          </div>
          <h4>Parameters</h4>
          <div className="code-snippet-section">
            <h3>STAGING</h3>
            <div className="code-snippet-box">
              <pre>
                <code>
                  PLUGIN:
                  <br />
                  <br />
                  &lt;link rel="stylesheet"
                  href="https://staging.zeepjet.com/zeepjet-main.css"&gt; <br />
                  <br />
                  <br />
                  &lt;!-- Zeepway Javascript plugin --&gt; <br />
                  &lt;script src="https://staging.zeepjet.com/zeepjet-main.js"
                  defer&gt; <br />
                  &lt;/script&gt;
                  <br />
                  <br />
                  &lt;!-- import cleave --&gt; <br />
                  &lt;script
                  src="https://cdnjs.cloudflare.com/ajax/libs/cleave.js/1.6.0/cleave.min.js"&gt;&lt;/script&gt;{" "}
                  <br />
                  <br />
                  <br />
                  <br />
                  API:
                  <br />
                  BASE URL: https://staging-api.zeepjet.com
                </code>
              </pre>
            </div>
          </div>
          <div className="code-snippet-section">
            <h3>PRODUCTION</h3>
            <div className="code-snippet-box">
              <pre>
                <code>
                  PLUGIN:
                  <br />
                  <br />
                  &lt;link rel="stylesheet"
                  href="https://dashboard.zeepway.com/zeepway-main.css"&gt;{" "}
                  <br />
                  <br />
                  <br />
                  &lt;!-- Zeepway Javascript plugin --&gt; <br />
                  &lt;script src="https://dashboard.zeepway.com/zeepway.main.js"
                  defer&gt; <br />
                  &lt;/script&gt;
                  <br />
                  <br />
                  &lt;!-- import cleave --&gt; <br />
                  &lt;script
                  src="https://cdnjs.cloudflare.com/ajax/libs/cleave.js/1.6.0/cleave.min.js"&gt;&lt;/script&gt;{" "}
                  <br />
                  <br />
                  <br />
                  <br />
                  API:
                  <br />
                  BASE URL: https://staging-api.zeepjet.com
                </code>
              </pre>
            </div>
          </div>
        </div>
      </div>
      <GetStarted />
      <Footer />
    </>
  );
};

export default Developers;
