import React, { useState, useEffect } from "react";
import quoteIcon from "../assets/icons/quoteIcon.svg";
import authorImg1 from "../assets/images/authorImg1.svg";
import authorImg2 from "../assets/images/authorImg2.svg";
import authorImg3 from "../assets/images/authorImg3.svg";
import arrowLeft from "../assets/icons/arrow-left.svg";
import arrowRight from "../assets/icons/arrow-right.svg";
import "../styles/testimonials.css";

const Testimonials = () => {
  const [hoveredId, setHoveredId] = useState(2);

  const testimonials = [
    {
      id: 1,
      quoteIcon: quoteIcon,
      quote:
        "As an avid shopper, I appreciate ZeepWay support for various payment methods. I can use my preferred mobile wallet for quick and secure payments. The process is straightforward, and I feel confident in the security of my transactions.",
      authorImg: authorImg1,
      author: "Matthew Simon",
      occupation: "Small Business Owner",
    },
    {
      id: 2,
      quoteIcon: quoteIcon,
      quote:
        "ZeepWay has been a game-changer for my small business. It's easy to set up and provides a wide range of payment options for my customers. The real-time transaction reporting helps me keep a close eye on my business finances. I highly recommend it.",
      authorImg: authorImg2,
      author: "Ayodeji Ayodeji",
      occupation: "Small Business Owner",
    },
    {
      id: 3,
      quoteIcon: quoteIcon,
      quote:
        "As an avid shopper, I appreciate ZeepWay support for various payment methods. I can use my preferred mobile wallet for quick and secure payments. The process is straightforward, and I feel confident in the security of my transactions.",
      authorImg: authorImg3,
      author: "Faith Okon",
      occupation: "Small Business Owner",
    },
  ];

   const goToNextTestimonial = () => {
    setHoveredId((currentId) => (currentId === testimonials.length ? 1 : currentId + 1));
  };

  const goToPrevTestimonial = () => {
    setHoveredId((currentId) => (currentId === 1 ? testimonials.length : currentId - 1));
  };

  useEffect(() => {
    setHoveredId(2);
  }, []);

  return (
    <>
      <div className="testimonials">
        {testimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className={`testimonial ${
              hoveredId === testimonial.id ? "hovered" : ""
            }`}
            // onMouseEnter={() => setHoveredId(testimonial.id)}
            // onMouseLeave={() => setHoveredId(null)}
          >
            <img
              src={testimonial.quoteIcon}
              alt="quote icon"
              style={{ marginBottom: "10px" }}
            />
            <blockquote>{testimonial.quote}</blockquote>
            <footer className="author-section">
                <img src={testimonial.authorImg} alt="author" />
              <div>
                <p className="author">{testimonial.author}</p>
                <p className="occupation">{testimonial.occupation}</p>
              </div>
            </footer>
          </div>
        ))}
      </div>
      <div className="arrow-section">
        <img src={arrowLeft} alt="previous" onClick={goToPrevTestimonial}/>
        <img src={arrowRight} alt="next" onClick={goToNextTestimonial}/>
      </div>
    </>
  );
};

export default Testimonials;
