import React from "react";
import getStartedImg from "../assets/images/get-started.svg";
import arrowRedirect from "../assets/icons/arrow-redirect.svg";
import "../styles/getStarted.css";


const GetStarted = () => {

  const navigateToRegistration = () =>{
    window.location.href = "https://dashboard.zeepway.com/auth/register";
  }

  return (
    <>
      <div className="get-started-container">
        <div className="get-started-container-text">
          <h2>Get Started with PayZeep’s Payment Gateway</h2>
          <p>
            Ready to enhance your payment processing capabilities?
            <br />
            Sign up for a Payzeep Payment Gateway account today and
            revolutionize the way you do business.
          </p>
          <button className="get-started-btn" onClick={navigateToRegistration}>
            Get Started
            <img src={arrowRedirect} alt="redirect" />
          </button>
        </div>
        <img src={getStartedImg} alt="get started" />
      </div>
    </>
  );
};
export default GetStarted;
