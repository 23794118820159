import React, { useState } from "react";
import { ReactComponent as ChevronDownIcon } from "../assets/icons/chevron-down.svg";
import Navigation from "../components/Navigation";
import faqHeaderImg from "../assets/images/faq-header.svg";
import NewsLetter from "../components/NewsLetter";
import GetStarted from "../components/GetStarted";
import Footer from "../components/Footer";
import "../styles/faq.css";

const FAQItem = ({ faq, index, toggleFAQ, activeIndex }) => (
  <div className={`faq-item ${activeIndex === index ? "open" : ""}`}>
    <div className="faq-question" onClick={() => toggleFAQ(index)}>
      {faq.question}
      <ChevronDownIcon
        className={`chevron-icon ${activeIndex === index ? "rotated" : ""}`}
      />
    </div>
    <div className="faq-answer">{faq.answer}</div>
  </div>
);

const FAQs = () => {
  
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is a payment gateway, and how does it work?",
      answer: "A payment gateway is a merchant service ...",
    },
    {
      question: "Why should I use PayZeep’s Payment Gateway?",
      answer: "A payment gateway is a merchant service ...",
    },
    {
      question: "Is PayZeep Fintech Payment Gateway secure?",
      answer:
        "Yes, security is our top priority. We implement advanced security measures, including encryption and fraud protection, to ensure the safety of your transactions and customer data.",
    },
    {
      question:
        "What types of payments can I accept with PayZeep Payment Gateway?",
      answer:
        "Yes, security is our top priority. We implement advanced security measures, including encryption and fraud protection, to ensure the safety of your transactions and customer data.",
    },
    {
      question:
        "How do I integrate PayZeep Payment Gateway into my website or system?",
      answer:
        "Yes, security is our top priority. We implement advanced security measures, including encryption and fraud protection, to ensure the safety of your transactions and customer data.",
    },
    {
      question: "Can I access transaction reports and analytics?",
      answer:
        "Yes, security is our top priority. We implement advanced security measures, including encryption and fraud protection, to ensure the safety of your transactions and customer data.",
    },
    {
      question: "Is there a setup fee for using PayZeep’s Payment Gateway?",
      answer:
        "Yes, security is our top priority. We implement advanced security measures, including encryption and fraud protection, to ensure the safety of your transactions and customer data.",
    },
    {
      question: "How quickly will I receive funds from transactions?",
      answer:
        "Yes, security is our top priority. We implement advanced security measures, including encryption and fraud protection, to ensure the safety of your transactions and customer data.",
    },
    {
      question:
        "What kind of customer support is available for PayZeep’s Payment Gateway users?",
      answer:
        "Yes, security is our top priority. We implement advanced security measures, including encryption and fraud protection, to ensure the safety of your transactions and customer data.",
    },
    {
      question:
        "Can I accept international payments with PayZeep’s Payment Gateway?",
      answer:
        "Yes, security is our top priority. We implement advanced security measures, including encryption and fraud protection, to ensure the safety of your transactions and customer data.",
    },
  ];

  return (
    <>
      <div className="faq-top">
        <Navigation />
        <div className="faq-top-container">
          <div className="faq-top-container-text">
            <h1>
              Frequently
              <br /> Asked Questions
            </h1>
            <p>
              Get quick answers to your questions about PayZeep’s Payment
              <br />
              Gateway.
            </p>
            <input type="text" placeholder="Search for your queestion" />
          </div>
          <img src={faqHeaderImg} alt="question mark" />
        </div>
      </div>

      <div className="faq-section">
        {faqData.map((faq, index) => (
          <FAQItem
            key={index}
            faq={faq}
            index={index}
            toggleFAQ={toggleFAQ}
            activeIndex={activeIndex}
          />
        ))}
      </div>
        <NewsLetter />
        <GetStarted />
      <Footer />
    </>
  );
};
export default FAQs;
