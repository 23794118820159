import React from "react";
import Navigation from "../components/Navigation";
import pzHeaderImg from "../assets/images/pzHeaderImg.svg";
import pzGatewayImg from "../assets/images/pzGatewayImg.svg";
import userAvatar from "../assets/icons/user-avatar.svg";
import integrationImg from "../assets/icons/integration.svg";
import userWallet from "../assets/icons/wallet.svg";
import pgwCard from "../assets/images/PGW-card.svg";
import pgwTransfer from "../assets/images/PGW-transfer.svg";
import pgwUSSD from "../assets/images/PGW-ussd.svg";
import offer1 from "../assets/images/offer-1.svg";
import offer2 from "../assets/images/offer-2.svg";
import offer3 from "../assets/images/offer-3.svg";
import securityIcon from "../assets/icons/security.svg";
import versatileIcon from "../assets/icons/versatile.svg";
import reportingIcon from "../assets/icons/reporting.svg";
import bankIcon from "../assets/icons/bank.svg";
import settlementIcon from "../assets/icons/settlement.svg";
import supportIcon from "../assets/icons/support.svg";
import Testimonials from "../components/Testimonials";
import nipostLogo from "../assets/images/nipost-logo.svg";
import cavistaLogo from "../assets/images/cavista-logo.svg";
import agbeyewaLogo from "../assets/images/agbeyewa-logo.svg";
import signUpImg from "../assets/images/signUpImg.svg";
import Footer from "../components/Footer";
import "../styles/home.css";

const Home = () => {
  const navigateToRegistration = () => {
    window.location.href = "https://dashboard.zeepway.com/auth/register";
  };
  return (
    <>
      <Navigation />
      <div>
        <div className="landing-top">
          <div className="landing-heading">
            <h1>The Easiest and Most Affordable Way to Collect Payments</h1>
            <p>
              With ZeepWay, Businesses can accept payments anytime, any day and
              anywhere in Nigeria with no hassle.
            </p>
            <div className="landing-action-btns">
              <button
                style={{ backgroundColor: "#0516C8" }}
                onClick={navigateToRegistration}
              >
                Create Free Account
              </button>
              <button style={{ backgroundColor: "#23242F" }}>
                <a
                  href="mailto:sales@payzeep.com?subject=Interested%20In%20Working%20With%20You"
                  className="contact-sales"
                >
                  Contact Sales
                </a>
              </button>
            </div>
          </div>
          <div>
            <img src={pzHeaderImg} alt="header" />
          </div>
        </div>

        <div className="landing-top landing-top2 pt-2-mobile">
          <div>
            <img src={pzGatewayImg} alt="payment gateway" />
          </div>
          <div className="landing-heading">
            <div className="lh-text">
              <h2>A Payment Gateway Designed For Your Business</h2>
              <p>
                ZeepWay is your gateway to secure an efficient inward and
                outward transactions all at an affordable cost. Our robust
                payment processing solution empowers businesses of all sizes to
                accept payments, streamline transactions and provide a smooth
                payment experience for your customers.
              </p>
            </div>
            <button
              style={{ backgroundColor: "#0516C8" }}
              onClick={navigateToRegistration}
            >
              Create Free Account
            </button>
          </div>
        </div>

        <div className="how-it-works-section">
          <h2>How It Works</h2>
          <div className="how-it-works-grid">
            <div className="step-container">
              <img src={userAvatar} alt="user avatar" />
              <h4>Setup Account</h4>
              <p>
                Create your free merchant account in just a few minutes. Provide
                essential information about your business, and you're on your
                way to enjoying seamless and efficient transactions.
              </p>
            </div>
            <div className="step-container">
              <img src={integrationImg} alt="integration img" />
              <h4>Integrate</h4>
              <p>
                We offer user-friendly SDKs and APIs to effortlessly weave our
                payment gateway into your website or application. Whether you're
                a seasoned developer or just starting, our tools make the
                process straightforward.
              </p>
            </div>
            <div className="step-container">
              <img src={userWallet} alt="user wallet" />
              <h4>Start Collecting</h4>
              <p>
                With your account set up and integration complete, you're ready
                to start collecting payments. Customers can now make purchases
                or payments with ease. Our payment gateway handles the rest,
                from securely processing transactions to providing real-time
                transaction records.
              </p>
            </div>
          </div>
        </div>

        <div className="payment-methods-section">
          <h2 style={{ textAlign: "center" }}>Supported Payment Methods</h2>
          <div className="payment-method cards">
            <div className="pm-text">
              <h4>
                Credit & Debit <br /> Cards
              </h4>
              <p>
                ZeepWay allows you to accept payments via credit and debit cards
                such as Visa, Mastercard and Verve at low transaction fees.
              </p>
            </div>
            <img src={pgwCard} alt="" />
          </div>
          <div className="payment-method transfer">
            <img src={pgwTransfer} alt="" />
            <div className="pm-text">
              <h4>Mobile & Internet Transfer</h4>
              <p>
                Your customers can also make payments to you on your website or
                app by initiating a simple interbank transfer via internet
                banking or their mobile banking app.
              </p>
            </div>
          </div>
          <div className="payment-method ussd">
            <div className="pm-text ussd-text">
              <h4>USSD</h4>
              <p>
                For quick and accessible payments, your customers can use USSD
                codes. No need for a smartphone or internet connection – they
                simply dial a code to complete their transactions.
              </p>
            </div>
            <img src={pgwUSSD} alt="" />
          </div>
        </div>

        <div className="offerings-section">
          <h2
            style={{
              textAlign: "center",
              marginBottom: "90px",
              padding: "0 20px",
            }}
          >
            ZeepWay Offers Way More
            <br />
            than the Competition
          </h2>
          <div className="flex-grid-container">
            <div className="flex-grid-row">
              <div>
                <img src={offer1} alt="" className="offer-img" />
              </div>
              <div className="flexbox-t">
                <div style={{ padding: "24px" }}>
                  <img src={securityIcon} alt="security" />
                  <h4>State of the Art Security</h4>
                  <p>
                    Protecting your business and your customers is our priority.
                    ZeepWay employs cutting-edge security measures to ensure
                    safe and secure transactions.
                  </p>
                </div>
              </div>
              <div className="flexbox-t">
                <div style={{ padding: "24px" }}>
                  <img src={versatileIcon} alt="versatile" />
                  <h4>Versatile and Customizable</h4>
                  <p>
                    Whether you operate an e-commerce store, a
                    subscription-based service, or a brick-and-mortar business,
                    Zeepway can be tailored to meet your unique needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-grid-row">
              <div className="flexbox-t">
                <div style={{ padding: "24px" }}>
                  <img src={reportingIcon} alt="reporting" />
                  <h4>Comprehensive Reporting</h4>
                  <p>
                    Access detailed transaction reports and analytics to gain
                    insights into your sales and customer behaviour.
                  </p>
                </div>
              </div>
              <div className="flexbox-t">
                <div style={{ padding: "24px" }}>
                  <img src={bankIcon} alt="icon" />
                  <h4>All Nigerian Banks Compatible</h4>
                  <p>
                    You can accept payments from all banks in Nigeria with
                    Zeepway.
                  </p>
                </div>
              </div>
              <div>
                <img src={offer2} alt="" className="offer-img" />
              </div>
            </div>
            <div className="flex-grid-row">
              <div>
                <img src={offer3} alt="" className="offer-img" />
              </div>
              <div className="flexbox-t">
                <div style={{ padding: "24px" }}>
                  <img src={settlementIcon} alt="settlement" />
                  <h4>Instant Settlement</h4>
                  <p>
                    ZeepWay allows for instant settlement. However, this can be
                    adjusted to what is best for your cash flow.
                  </p>
                </div>
              </div>
              <div className="flexbox-t">
                <div style={{ padding: "24px" }}>
                  <img src={supportIcon} alt="icon" />
                  <h4>24/7 Support</h4>
                  <p>
                    Our dedicated support team is ready to assist you 24/7,
                    ensuring that you have all the help you need whenever you
                    need it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="testimonial-section">
          <div className="testimonial-header">
            <h2>What Our Users Are Saying</h2>
            <p>Words from our satisfied customers: What they say about us.</p>
          </div>
          <div className="testimonials-container">
            <Testimonials />
          </div>
        </div>

        <div className="trusted-by">
          <h4>We are trusted by</h4>
          <div className="trusted-by-imgs">
            <img src={nipostLogo} alt="nipost logo" />
            <img src={cavistaLogo} alt="cavista logo" />
            <img src={agbeyewaLogo} alt="agbeyewa logo" />
          </div>
        </div>

        <div className="create-account">
          <div className="create-account-text">
            <h2>Ready to Get Started?</h2>
            <p>
              Create an account and instantly start
              <br />
              accepting payments today.
            </p>
            <button
              style={{ backgroundColor: "#0516C8" }}
              onClick={navigateToRegistration}
            >
              Create Free Account
            </button>
            <button style={{ backgroundColor: "#23242F" }}>
              Contact Sales
            </button>
          </div>
          <div>
            <img src={signUpImg} alt="sign up" />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Home;
