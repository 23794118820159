import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import Navigation from "../components/Navigation";
import contactUsHeaderImg from "../assets/images/contact-us-header.svg";
import arrowRedirect from "../assets/icons/arrow-redirect.svg";
import facebookIcon from "../assets/icons/facebook.svg";
import twitterIcon from "../assets/icons/twitter.svg";
import instagramIcon from "../assets/icons/instagram.svg";
import phoneCallIcon from "../assets/icons/phone-call.svg";
import emailIcon from "../assets/icons/mail.svg";
import socialMediaIcon from "../assets/icons/social-media.svg";
import "../styles/contact.css";


const ContactUs = () => {

  const navigateToFacebook = () => {
    window.location.href = "https://www.facebook.com/payzeep";
  };

  const navigateToTwitter = () => {
    window.location.href = "https://twitter.com/payzeepng";
  };

  const navigateToInstagram = () => {
    window.location.href = "https://www.instagram.com/payzeep/";
  };

  return (
    <>
      <Navigation />
      <div className="contact-section-top">
        <div className="contact-section-top-text">
          <h1>Hey, Thanks For Choosing Our Service.</h1>
          <p>
            Have questions or need assistance? Our dedicated support team is
            here to help you every step of the way. Thank you for choosing
            PayZeep Payment Gateway for your payment processing needs.
          </p>
          <button className="contact-btn" id="message-btn">
            <a href="#send-message" className="contact-sales">Send a Message</a>
            <img src={arrowRedirect} alt="redirect" />
          </button>
        </div>
        <img src={contactUsHeaderImg} alt="contact us" />
      </div>

      <div className="message-section" id='send-message'>
        <div className="message-section-body">
          <h2>Send Us A Message</h2>
          <p>
            Get in touch with us with your complaints, we are always ready to
            serve you 24/7.
          </p>
          <div className="message-form-body">
            <div className="message-form-row">
              <label htmlFor="name">
                First Name
                <br />
                <input type="text" placeholder="Enter your first name" />
              </label>
              <label htmlFor="name">
                Last Name
                <br />
                <input type="text" placeholder="Enter your last name" />
              </label>
            </div>
            <div className="message-form-row">
              <label htmlFor="email">
                Email Address
                <br />
                <input type="text" placeholder="Enter your email address" />
              </label>
              <label htmlFor="phone_number">
                Phone Number
                <br />
                <input type="text" placeholder="Enter your phone number" />
              </label>
            </div>
            <div className="message-form-textarea">
              <label htmlFor="message">
                Send us a message
                <br />
                <textarea
                  name="message"
                  id="message"
                  cols="87"
                  rows="10"
                ></textarea>
              </label>
            </div>
            <buttton className="contact-btn form-btn">
              Get In Touch
              <img src={arrowRedirect} alt="redirect" />
            </buttton>
          </div>
        </div>
      </div>

      <div className="contact-options-section">
        <div className="contact-option">
          <img
            src={phoneCallIcon}
            alt="phone call"
            style={{ padding: "20px" }}
          />
          <p>Call Us</p>
          <span>(+234)9088220236</span>
        </div>
        <div className="contact-option">
          <img src={emailIcon} alt="email" />
          <p>Send Us An Email</p>
          <a href="mailto:sales@payzeep.com?subject=Interested%20In%20Working%20With%20You">
          projects@payzeep.com</a>
        </div>
        <div className="contact-option">
          <img src={socialMediaIcon} alt="social media" />
          <p>Connect With Us</p>
          <div className="contact-icons">
            <img src={facebookIcon} alt="facebook" onClick={navigateToFacebook}/>
            <img src={twitterIcon} alt="twitter" onClick={navigateToTwitter}/>
            <img src={instagramIcon} alt="instagram" onClick={navigateToInstagram}/>
          </div>
        </div>
      </div>

        <GetStarted />
        <Footer />
    </>
  );
};
export default ContactUs;
